import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";

import Swipeable from "../Swipeable";
import Carousel from "../Carousel";
import MagicLink from "../MagicLink";
import Text from "../Text";
import ScrollableOverflow from "../ScrollableOverflow";
import Row from "../Row";
import ScienceTeamDetailsCard from "./ScienceTeamDetailsCard";

import { Color, Font, responsive } from "../../utils/style";
import { Icons } from "../../utils/svg";

const Content = styled.div.attrs({
  className: "col-12",
})`
  ${responsive.sm`
    padding: 0;
  `}

  margin-bottom: 80px;
  ${responsive.md`
    margin-bottom: 120px;
  `};
`;

const Title = styled.div`
  margin-bottom: 24px;
  ${responsive.md`
    margin-bottom: 40px;
  `}

  p {
    margin: 0;
    text-transform: uppercase;
    opacity: 0.56;
    font-weight: 500px;

    font-size: 14px;
    letter-spacing: 0px;
    line-height: 20px;

    ${responsive.md`
      font-size: 18px;
      letter-spacing: 1.2px;
      line-height: 28px;
    `}
  }
`;

const OverflowContainer = styled.div.attrs({
  className: "container",
  role: "section",
})`
  overflow: visible !important;
`;

const Headline = styled.div`
  flex-shrink: 0;

  max-width: 100%;
  ${responsive.sm`
    max-width: 460px;
  `}
  ${responsive.md`
    max-width: 614px;
  `}
  ${responsive.lg`
    max-width: 769px;
  `}

  p {
    ${Font.circular}
    margin: 0;
    font-weight: 500;

    font-size: 22px;
    letter-spacing: -0.2px;
    line-height: 32px;

    ${responsive.md`
      font-size: 30px;
      letter-spacing: -0.4px;
      line-height: 36px;
    `}
  }
`;

const HeadlineContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  margin-bottom: 32px;
  ${responsive.md`
    margin-bottom: 56px;
  `}
`;

const ControlsContainer = styled.div`
  display: none;
  ${responsive.sm`
    display: flex;
    align-items: center;
  `}
`;

const MembersContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 2px solid ${Color.ritualBlue};
  padding-top: 16px;

  ${responsive.sm`
    display: none;
  `}
`;

const MemberCount = styled.div`
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 24px;
`;

const MobileControlsContainer = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 80px;
`;

const ControlButton = styled.button`
  height: 40px;
  width: 40px;
  padding-bottom: 0;
  position: relative;

  ${responsive.md`
    height: 56px;
    width: 56px;
  `}

  border: 2px solid ${Color.ritualBlue};
  background: transparent;

  ${responsive.md`
    border-width: 3px;
  `}

  border-radius: 50%;

  &:disabled {
    opacity: .24;
  }

  margin-right: 16px;

  &:last-of-type {
    margin-right: 0px;
  }

  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    position: absolute;
    top: calc(50% - 6px);
    right: 0;
    bottom: 0;
    left: calc(50% - 11px);
    height: 12px;
    width: 22px;

    ${responsive.md`
      height: 18px;
      width: 28px;
      top: calc(50% - 9px);
      left: calc(50% - 14px);
    `};
  }
`;

const Slides = styled.div`
  display: inline-flex;
  white-space: normal;
  box-sizing: border-box;
  padding-bottom: 40px;

  ${responsive.sm`
    padding-bottom: 80px;
  `}

  ${responsive.md`
    padding-bottom: 120px;
  `}
`;

const Slide = styled.div`
  flex-shrink: 0;
  width: 182px;
  ${responsive.sm`
    width: 160px;
  `}
  ${responsive.md`
    width: 214px;
  `}
  ${responsive.lg`
    width: 270px;
  `}

  &:not(:last-child) {
    margin-right: 14px;
    ${responsive.sm`
      margin-right: 20px;
    `}
    ${responsive.md`
      margin-right: 26px;
    `}
    ${responsive.lg`
      margin-right: 30px;
    `}
  }
`;

const SlideHoverIcon = styled.div`
  position: absolute;
  bottom: 8px;
  right: 8px;
  ${responsive.md`
    bottom: 16px;
    right: 16px;
  `}
  ${responsive.lg`
    bottom: 8px;
    right: 8px;
  `}

  height: 40px;
  width: 40px;
  ${responsive.md`
    height: 44px;
    width: 44px;
  `}

  border-radius: 50%;

  background-color: white;

  display: flex;
  ${responsive.sm`
    display: none;
  `}

  justify-content: center;
  align-items: center;

  h1 {
    font-size: 32px;
    line-height: 32px;
    margin: 0;
  }

  svg {
    width: 18px;
  }
`;

const SlideImage = styled.div`
  width: 100%;
  position: relative;

  height: 182px;
  ${responsive.sm`
    height: 160px;
  `}
  ${responsive.md`
    height: 245px;
  `}
  ${responsive.lg`
    height: 309px;
  `}
  cursor: pointer;
  overflow: hidden;

  &.active {
    height: 500px;
  }

  &:hover {
    ${SlideHoverIcon} {
      display: flex;
    }
  }

  background-color: lightgrey;
`;

const SlideName = styled.div`
  margin-top: 8px;
  ${responsive.md`
    margin-top: 16px;
  `}

  p {
    margin: 0;
    font-weight: 500;
    letter-spacing: 0px;

    font-size: 14px;
    line-height: 24px;

    ${responsive.md`
      font-size: 18px;
      line-height: 28px;
    `}
  }
`;

const SlideDescription = styled.div`
  margin-top: 8px;

  p {
    opacity: 0.56;
    margin: 0;
    font-weight: 300;
    letter-spacing: 0px;

    font-size: 14px;
    line-height: 24px;

    ${responsive.md`
      font-size: 16px;
      line-height: 26px;
    `}
  }
`;

const CuriousContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CuriousLine = styled.div`
  width: 32px;
  height: 4px;
  margin-bottom: 24px;
  background-color: ${Color.ritualBlue};
`;

const CuriousText = styled.div`
  p {
    text-align: center;
    font-size: 22px;
    font-weight: 500;
    letter-spacing: -0.2px;
    line-height: 32px;
    margin: 0;
  }

  a {
    border-bottom: 2px solid ${Color.ritualBlue};
  }
`;

export default class ScienceTeam extends React.Component {
  constructor(props) {
    super(props);

    this.slideRefs = [];
    this.numberOfSlides = props.slides.length;

    this.scrollableContent = null;

    this.state = {
      activeSlideIndex: 0,
      detailsIndex: -1,
      ignoreBounds: false,
      leftButtonEnabled: false,
      rightButtonEnabled: true,
    };
  }

  componentDidUpdate(_, prevState) {
    if (
      prevState.activeSlideIndex !== this.state.activeSlideIndex &&
      this.scrollableContent
    ) {
      this.scrollableContent.scrollToIndex(this.state.activeSlideIndex, "left");
    }
  }

  disableLeftButton() {
    if (!this.state.leftButtonEnabled) return;
    this.setState({
      leftButtonEnabled: false,
    });
  }

  enableLeftButton() {
    if (this.state.leftButtonEnabled) return;
    this.setState({
      leftButtonEnabled: true,
    });
  }

  disableRightButton() {
    if (!this.state.rightButtonEnabled) return;
    this.setState({
      rightButtonEnabled: false,
    });
  }

  enableRightButton() {
    if (this.state.rightButtonEnabled) return;
    this.setState({
      rightButtonEnabled: true,
    });
  }

  /**
   * We need to update the active slide index and let the carousel know
   * we are going forwards. This will trigger an animation when updated.
   */
  handleGoToNextSlide() {
    const { activeSlideIndex } = this.state;
    const nextIndex =
      activeSlideIndex === this.numberOfSlides - 1
        ? activeSlideIndex
        : activeSlideIndex + 1;

    if (nextIndex === activeSlideIndex) return;

    this.setState({
      activeSlideIndex: nextIndex,
    });
  }

  /**
   * We need to update the active slide index and let the carousel know
   * we are going backwords. This will trigger an animation when updated.
   */
  handleGoToPreviousSlide() {
    const { activeSlideIndex } = this.state;
    const prevIndex =
      activeSlideIndex === 0 ? activeSlideIndex : activeSlideIndex - 1;

    if (prevIndex === activeSlideIndex) return;

    this.setState({
      activeSlideIndex: prevIndex,
    });
  }

  handleSlideClick(index) {
    this.setState({
      detailsIndex: index,
    });
  }

  hideDetails() {
    this.setState({
      detailsIndex: -1,
    });
  }

  generateCarouselSlides(slides) {
    return slides.map((slide, index) => {
      return (
        <ScienceTeamDetailsCard
          name={slide.name}
          title={slide.currentTitle}
          expertise={slide.expertise}
          image={slide.image}
          bio={slide.bio}
        />
      );
    });
  }

  render() {
    const { slides, readMoreArticle } = this.props;
    const {
      activeSlideIndex,
      detailsIndex,
      leftButtonEnabled,
      rightButtonEnabled,
    } = this.state;

    return (
      <OverflowContainer>
        <Row>
          <Content>
            <Title>
              <p>Our Science Team</p>
            </Title>

            <HeadlineContainer>
              <Headline>
                <p>
                  <Text
                    id="clinical.science-team.headline"
                    defaultMessage={`Here at Ritual, every ingredient, form, and formulation is
                  obsessively researched by our in-house team of scientists and
                  researchers.`}
                  />
                </p>
              </Headline>
              <ControlsContainer>
                <Controls
                  previousSlide={this.handleGoToPreviousSlide.bind(this)}
                  nextSlide={this.handleGoToNextSlide.bind(this)}
                  enableLeft={leftButtonEnabled}
                  enableRight={rightButtonEnabled}
                />
              </ControlsContainer>
            </HeadlineContainer>

            <ScrollableOverflow
              ref={r => {
                this.scrollableContent = r;
              }}
              disableScroll={true}
              childRefs={this.slideRefs}
              isAtStart={this.disableLeftButton.bind(this)}
              isAfterStart={this.enableLeftButton.bind(this)}
              isAtEnd={this.disableRightButton.bind(this)}
              isBeforeEnd={this.enableRightButton.bind(this)}
            >
              <Swipeable
                onSwipedRight={this.handleGoToPreviousSlide.bind(this)}
                onSwipedLeft={this.handleGoToNextSlide.bind(this)}
              >
                <Slides>
                  {slides.map((slide, index) => {
                    return (
                      <Slide
                        index={index}
                        key={index}
                        ref={r => (this.slideRefs[index] = r)}
                        onClick={() => this.handleSlideClick(index)}
                      >
                        <SlideImage key={slide.name}>
                          {slide.clinicalImage && (
                            <Img
                              fluid={slide.clinicalImage.fluid}
                              loading="eager"
                              alt={`${slide.name} - ${slide.currentTitle}`}
                              style={{
                                width: "100%",
                                height: "100%",
                                userSelect: "none",
                                userDrag: "none",
                                pointerEvents: "none",
                                touchCallout: "none",
                              }}
                              imgStyle={{
                                objectPosition: "top center",
                              }}
                            />
                          )}
                          <SlideHoverIcon
                            className={
                              index === activeSlideIndex ? "active" : ""
                            }
                          >
                            <Icons.PlusNoBG />
                          </SlideHoverIcon>
                        </SlideImage>
                        <SlideName>
                          <p>{slide.name}</p>
                        </SlideName>
                        <SlideDescription>
                          <p>{slide.currentTitle}</p>
                          <p>{slide.expertise}</p>
                        </SlideDescription>
                      </Slide>
                    );
                  })}
                </Slides>
              </Swipeable>
            </ScrollableOverflow>

            <MembersContainer>
              <MemberCount>
                <p>
                  <Text
                    id="clinical.science-team.member-count"
                    defaultMessage="{number} Members"
                    values={{ number: this.numberOfSlides }}
                  />
                </p>
              </MemberCount>
              <MobileControlsContainer>
                <Controls
                  previousSlide={this.handleGoToPreviousSlide.bind(this)}
                  nextSlide={this.handleGoToNextSlide.bind(this)}
                  enableLeft={leftButtonEnabled}
                  enableRight={rightButtonEnabled}
                />
              </MobileControlsContainer>
            </MembersContainer>

            <CuriousContainer>
              <CuriousLine />
              <CuriousText>
                <p>
                  <Text
                    id="clinical.science-team.curious-text"
                    defaultMessage="Curious what makes a good clinical trial?"
                  />{" "}
                  <MagicLink
                    to={`/articles/${readMoreArticle.slug}`}
                    aria-label="Clinical Trial Study Design"
                  >
                    <Text
                      id="clinical.science-team.curious-cta"
                      defaultMessage="Read More"
                    />
                  </MagicLink>
                </p>
              </CuriousText>
            </CuriousContainer>

            {detailsIndex >= 0 && (
              <Carousel
                activeSlideIndex={detailsIndex}
                slides={this.generateCarouselSlides(slides)}
                close={this.hideDetails.bind(this)}
                repeat={true}
              />
            )}
          </Content>
        </Row>
      </OverflowContainer>
    );
  }
}

function Controls({ previousSlide, nextSlide, enableLeft, enableRight }) {
  return (
    <>
      <ControlButton
        disabled={!enableLeft}
        onClick={previousSlide}
        aria-label="Previous"
      >
        <Icons.ArrowRoundedLeft />
      </ControlButton>
      <ControlButton
        disabled={!enableRight}
        onClick={nextSlide}
        aria-label="Next"
      >
        <Icons.ArrowRoundedRight />
      </ControlButton>
    </>
  );
}
