import React from "react";
import MagicModal from "../MagicModal";
import styled, { css } from "styled-components";
import { rem, responsive, Font } from "../../utils/style";

const contentStyle = css`
  padding: 48px 24px 40px;

  ${responsive.sm`
    padding: 40px;
    max-width: 460px;
    max-height: 614px;
  `};

  ${responsive.md`
    padding: 56px 80px;
    max-width: 774px;
  `};

  ${responsive.lg`
    padding: 56px 100px;
    max-width: 770px;
  `};
`;

const ModalContentWrapper = styled.div`
  p {
    font-size: ${rem(16)};
    line-height: 22px;
    letter-spacing: 0;
    margin-bottom: 0;

    ${responsive.lg`
      font-size: ${rem(18)};
      line-height: 28px;
    `};
  }
`;

const DesignFeature = styled.div`
  margin-bottom: 24px;

  h4 {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 26px;

    ${responsive.md`
      font-size: 18px;
      letter-spacing: 0px;
      line-height: 28px;
    `}
  }

  p {
    ${Font.dutch};
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 26px;
    padding-bottom: 0px;

    ${responsive.md`
      font-size: 18px;
      line-height: 28px;
    `}
  }
`;

export default class StudyDesignModal extends React.Component {
  constructor(props) {
    super(props);
  }

  renderDesignFeature(feature, i) {
    return (
      <DesignFeature key={i}>
        <h4>{feature.title}</h4>
        <p>{feature.description}</p>
      </DesignFeature>
    );
  }

  render() {
    let { isOpen, features, onRequestClose } = this.props;

    return (
      <MagicModal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        contentStyle={contentStyle}
        mobileFullPage={true}
      >
        <ModalContentWrapper>
          {features.map((feature, index) => {
            return this.renderDesignFeature(feature, index);
          })}
        </ModalContentWrapper>
      </MagicModal>
    );
  }
}
