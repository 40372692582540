import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import styled from "styled-components";

// Utils
import { Color } from "../../utils/style";

// Components
import PageSEO from "../../components/seo/Page";
import ClinicalHero from "../../components/clinical/Hero";
import Introduction from "../../components/clinical/Introduction";
import ScienceTeam from "../../components/clinical/ScienceTeam";
import EfwCta from "../../components/clinical/EfwCta";
import IngredientGraph from "../../components/clinical/IngredientGraph";
import ProductCategoryCards from "../../components/product/ProductCategoryCards";
import PageHero from "../../components/PageHero";
import VideoOverlay from "../../components/clinical/VideoOverlay";

// Styled Elements
const ClinicalPageWrapper = styled.div.attrs({
  className: "mb-2 mb-md-4",
})``;

const Omega3Copy = styled.p`
  font-weight: 500 !important;

  a {
    border-bottom: 2px solid ${Color.ritualBlue};
  }
`;

export default class Clinical extends React.Component {
  constructor(props) {
    super(props);

    const {
      pageTitle,
      pageDescription,
      socialImage,
    } = props.data.allContentfulClinicalPage.edges[0].node;

    this.state = {
      seo: {
        pagePath: "clinical-study",
        title: pageTitle,
        description: pageDescription,
        image: {
          url: socialImage.file.url,
          width: socialImage.file.details.image.width,
          height: socialImage.file.details.image.height,
        },
      },
    };

    this.videoRef = React.createRef();
  }

  componentDidMount() {
    this.props.updatePageData({
      label: "Clinical Study",
    });

    this.props.updateLayoutState({
      navColor: "#FFFFFF",
      banner: false,
    });
  }

  openVideo() {
    if (this.videoRef) {
      this.videoRef.current.openVideo();
    }
  }

  render() {
    const {
      allContentfulClinicalPage,
      vitaminD3Image,
      vitaminD3DesktopVideo,
      vitaminD3MobileVideo,
      vitaminD3MobileBenefit,
      vitaminD3DesktopImage1,
      vitaminD3DesktopImage2,
      vitaminD3DesktopImage3,
      omega3Image,
      omega3DesktopVideo,
      omega3MobileVideo,
      omega3MobileBenefit,
      omega3DesktopImage1,
      omega3DesktopImage2,
      omega3DesktopImage3,
      contentfulVideoContent,
    } = this.props.data;
    const {
      heroHeadline,
      heroImageDesktop,
      heroImageMobile,
      heroCta,
      scientists,
      efwCtaText,
      efwCtaDescription,
      efwCtaDesktopImage,
      efwCtaMobileImage,
      efwCtaMobileBackgroundImage,
      ingredientGraphContent,
      readMoreArticle,
      abstractCopy,
      abstractDocument,
    } = allContentfulClinicalPage.edges[0].node;

    const ingredientGraphData = JSON.parse(
      ingredientGraphContent.childMarkdownRemark.rawMarkdownBody,
    );

    const ingredientD3 = {
      image: vitaminD3Image, // Image Asset
      videoDesktop: vitaminD3DesktopVideo, // Video Asset,
      videoMobile: vitaminD3MobileVideo, // Video Asset,
      backgroundImage: efwCtaMobileBackgroundImage,
      benefitsMobileImage: vitaminD3MobileBenefit,
      benefitsDesktopImages: [
        vitaminD3DesktopImage1,
        vitaminD3DesktopImage2,
        vitaminD3DesktopImage3,
      ],
      graphSectionContent: {
        ...ingredientGraphData[0],
      },
    };

    const ingredientOmega3 = {
      image: omega3Image, // Image Asset
      videoDesktop: omega3DesktopVideo, // Video Asset,
      videoMobile: omega3MobileVideo, // Video Asset,
      backgroundImage: efwCtaMobileBackgroundImage,
      benefitsMobileImage: omega3MobileBenefit,
      benefitsDesktopImages: [
        omega3DesktopImage1,
        omega3DesktopImage2,
        omega3DesktopImage3,
      ],
      graphSectionContent: {
        ...ingredientGraphData[1],
      },
    };

    return (
      <>
        <ClinicalPageWrapper>
          <PageSEO {...this.state.seo} />

          <PageHero
            backgroundColor="#FFFFFF"
            imageMobile={heroImageMobile}
            imageDesktop={heroImageDesktop}
            imageObjectPosition="50% 100%"
          >
            <ClinicalHero
              headline={heroHeadline}
              imageDesktop={heroImageDesktop}
              imageMobile={heroImageMobile}
              cta={heroCta}
              openVideo={this.openVideo.bind(this)}
            />
          </PageHero>

          <Introduction {...this.props} />

          <IngredientGraph ingredient={ingredientD3} />

          <IngredientGraph ingredient={ingredientOmega3}>
            {abstractDocument && abstractCopy && (
              <Omega3Copy>
                {abstractCopy}{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  aria-label={abstractDocument.description}
                  href={abstractDocument.file.url}
                >
                  Read the abstract
                </a>
              </Omega3Copy>
            )}
          </IngredientGraph>

          <EfwCta
            description={efwCtaDescription}
            ctaText={efwCtaText}
            desktopImage={efwCtaDesktopImage}
            mobileImage={efwCtaMobileImage}
            mobileBackgroundImage={efwCtaMobileBackgroundImage}
          />

          <ScienceTeam slides={scientists} readMoreArticle={readMoreArticle} />

          <ProductCategoryCards className="mb-7 mb-md-9" />

          <Helmet bodyAttributes={{ class: "template" }} />
        </ClinicalPageWrapper>

        <VideoOverlay
          ref={this.videoRef}
          contentfulVideoContent={contentfulVideoContent}
        />
      </>
    );
  }
}

export const query = graphql`
  query ClinicalPageQuery($locale: String!) {
    contentfulVideoContent(
      node_locale: { eq: $locale }
      contentful_id: { eq: "3rlpajVvUQkuLwcRiw60TC" }
    ) {
      sources {
        file {
          url
          contentType
        }
        description
      }
      title
      videoLength
      captions {
        file {
          url
        }
        description
      }
    }
    allContentfulClinicalPage(
      filter: { node_locale: { eq: $locale } }
      limit: 1
    ) {
      edges {
        node {
          slug
          heroHeadline {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
          heroCta {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
          heroImageDesktop {
            fluid(maxWidth: 1920, quality: 90) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          heroImageMobile {
            fluid(maxWidth: 912, quality: 90) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          introHeader {
            childMarkdownRemark {
              html
            }
          }
          introLink {
            childMarkdownRemark {
              html
            }
          }
          introStudyDesignContent {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
          introResultsCTA
          introResultsProductLink
          introResultsHeadline {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
          introResultsData {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
          modalContent {
            childMarkdownRemark {
              html
            }
          }
          clinicalIcons {
            childMarkdownRemark {
              html
            }
          }
          efwCtaDescription
          efwCtaText
          efwCtaDesktopImage {
            description
            fluid(maxWidth: 970, quality: 90) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          efwCtaMobileImage {
            description
            fluid(maxWidth: 750, quality: 90) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          efwCtaMobileBackgroundImage {
            description
            fluid(maxWidth: 750, quality: 90) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          ingredientGraphContent {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
          pageTitle
          pageDescription
          socialImage {
            file {
              details {
                image {
                  width
                  height
                }
              }
              url
            }
          }
          scientists {
            id
            name
            currentTitle
            expertise
            clinicalQuote
            bio {
              childMarkdownRemark {
                html
              }
            }
            image {
              fluid(maxWidth: 240, quality: 100) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
              description
            }
            clinicalImage {
              fluid(maxWidth: 270, quality: 100) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
              description
            }
          }
          products {
            name {
              name
              childMarkdownRemark {
                html
              }
            }
            slug
            shortDescription
            summary
            cardImage {
              file {
                url
              }
              fluid(maxWidth: 1710, quality: 90) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
            cardBackgroundColor
          }
          readMoreArticle {
            slug
          }
          abstractCopy
          abstractDocument {
            file {
              url
            }
            description
          }
        }
      }
    }
    vitaminD3Image: contentfulAsset(
      node_locale: { eq: $locale }
      contentful_id: { eq: "5nz569oKcl6dGzHZJGNJj8" }
    ) {
      fluid(maxWidth: 570, quality: 90) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
      description
    }
    vitaminD3DesktopVideo: contentfulAsset(
      node_locale: { eq: $locale }
      contentful_id: { eq: "6wrLYFL0UesybHX2MalRYc" }
    ) {
      file {
        url
      }
      description
    }
    vitaminD3MobileVideo: contentfulAsset(
      node_locale: { eq: $locale }
      contentful_id: { eq: "4BPfgDciKGRNO7kUjxzrqx" }
    ) {
      file {
        url
      }
      description
    }
    vitaminD3MobileBenefit: contentfulAsset(
      node_locale: { eq: $locale }
      contentful_id: { eq: "5mGHWYkmyORuEqNW7LDWZ0" }
    ) {
      fluid(maxWidth: 570, quality: 90) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    vitaminD3DesktopImage1: contentfulAsset(
      node_locale: { eq: $locale }
      contentful_id: { eq: "36YMjgU0jz5QzglDzTB5n8" }
    ) {
      fluid(maxWidth: 570, quality: 90) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
      description
    }
    vitaminD3DesktopImage2: contentfulAsset(
      node_locale: { eq: $locale }
      contentful_id: { eq: "16TRYKpIgmKGtZeUs2Tnoz" }
    ) {
      fluid(maxWidth: 570, quality: 90) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
      description
    }
    vitaminD3DesktopImage3: contentfulAsset(
      node_locale: { eq: $locale }
      contentful_id: { eq: "35Jm63u1TKactMgz6pIvoy" }
    ) {
      fluid(maxWidth: 570, quality: 90) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
      description
    }
    omega3Image: contentfulAsset(
      node_locale: { eq: $locale }
      contentful_id: { eq: "Gj5bU89oJCctUNqwxhAff" }
    ) {
      fluid(maxWidth: 570, quality: 90) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
      description
    }
    omega3DesktopVideo: contentfulAsset(
      node_locale: { eq: $locale }
      contentful_id: { eq: "3OcMqvFPdcRhpH1Wp6NHNj" }
    ) {
      file {
        url
      }
      description
    }
    omega3MobileVideo: contentfulAsset(
      node_locale: { eq: $locale }
      contentful_id: { eq: "jbiICV3UDezSyS394GAl7" }
    ) {
      file {
        url
      }
      description
    }
    omega3MobileBenefit: contentfulAsset(
      node_locale: { eq: $locale }
      contentful_id: { eq: "34XvIEY9sWSwwfoycrUEYr" }
    ) {
      fluid(maxWidth: 570, quality: 90) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    omega3DesktopImage1: contentfulAsset(
      node_locale: { eq: $locale }
      contentful_id: { eq: "2uyOOWOtzw9RQNDuvXZ72g" }
    ) {
      fluid(maxWidth: 570, quality: 90) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
      description
    }
    omega3DesktopImage2: contentfulAsset(
      node_locale: { eq: $locale }
      contentful_id: { eq: "1BIOLlz5nzsawuKgbARTQF" }
    ) {
      fluid(maxWidth: 570, quality: 90) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
      description
    }
    omega3DesktopImage3: contentfulAsset(
      node_locale: { eq: $locale }
      contentful_id: { eq: "7ejKgGP2R1PlKHvFYURbZk" }
    ) {
      fluid(maxWidth: 570, quality: 90) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
      description
    }
  }
`;
