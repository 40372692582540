import React from "react";
import styled from "styled-components";
import { Color, responsive } from "../../utils/style";
import Img from "gatsby-image";

const CardWrapper = styled.div`
  padding: 22px 16px 22px 16px;
  ${responsive.sm`
    padding: 42px 60px 58px 60px;
  `};
  ${responsive.md`
    padding: 64px 80px 64px 80px;
  `};
`;

const Header = styled.div`
  display: flex;

  flex-direction: column;
  ${responsive.sm`
    flex-direction: row;
  `}

  margin-bottom:  16px;
  ${responsive.md`
    margin-bottom: 32px;
  `}
`;

const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ImageWrapper = styled.div`
  width: 84px;
  height: 84px;

  flex-shrink: 0;

  ${responsive.sm`
    width: 78px;
    height: 78px;
  `}
  ${responsive.md`
    width: 104px;
    height: 104px;
  `}
  ${responsive.lg`
    width: 128px;
    height: 128px;
  `}

  border-radius: 50%;
  background-color: lightgrey;

  overflow: hidden;

  margin-right:  16px;
  ${responsive.md`
    margin-right: 32px;
  `}

  margin-bottom: 8px;
  ${responsive.sm`
    margin-bottom: 0px;
  `}
`;

const Name = styled.div`
  p {
    font-weight: 500;
    margin: 0;

    font-size: 16px;
    letter-spacing: 0px;
    line-height: 26px;

    ${responsive.md`
      font-size: 22px;
      letter-spacing: -0.2px;
      line-height: 32px;
    `}
  }

  margin-bottom: 4px;
  ${responsive.md`
    margin-bottom: 8px;
  `}
`

const Title = styled.div`
  p {
    font-weight: 300;
    margin: 0;
    opacity: 0.56;

    font-size: 14px;
    letter-spacing: 0px;
    line-height: 20px;

    &:first-child {
      margin-bottom: 4px;
      ${responsive.md`
        margin-bottom: 8px;
      `}
    }
  }
`;

const Bio = styled.div`
  padding-top: 16px;
  ${responsive.md`
    padding-top: 32px;
  `}
  border-top: solid 1px ${Color.ritualBlue};

  p {
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0px;
    line-height: 20px;
  }
`;

export default class ScienceTeamDetailsCard extends React.Component {
  render() {
    const { name, title, expertise, image, bio } = this.props;

    return (
      <CardWrapper>
        <Header>
          <ImageWrapper>
            {image && <Img
              fluid={image.fluid}
              loading="eager"
              alt={`${name} - ${title}`}
              style={{
                width: "100%",
                height: "100%",
                userSelect: "none",
                userDrag: "none",
                pointerEvents: "none",
                touchCallout: "none",
              }}
              imgStyle = {{
                objectPosition: "top center"
              }}
            />}
          </ImageWrapper>
          <NameWrapper>
            <Name>
              <p>{name}</p>
            </Name>
            <Title>
              <p>{title}</p>
              <p>{expertise}</p>
            </Title>
          </NameWrapper>
        </Header>
        {bio && <Bio
          dangerouslySetInnerHTML={{ __html: bio.childMarkdownRemark.html }}
        />}
      </CardWrapper>
    );
  }
}
