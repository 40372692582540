import React from "react";
import styled, { keyframes } from "styled-components";

// Utils
import { media } from "../../utils/style";
import { lockScroll } from "../../utils/lockScroll";
import { Icons } from "../../utils/svg";

// Components
import MagicVideo from "../MagicVideo";

// Animations
const fadeIn = keyframes`
  from {opacity: 0}
  to {opacity: 1}
`;

// Styled Elements
const VideoOverlayWrapper = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: 2147483647;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  transition: opacity 500ms ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  opacity: 0;
  animation: ${fadeIn} 300ms forwards;
`;

const VideoContainer = styled.div`
  position: relative;
  display: flex;
  width: 85vw;
  height: 62vw;
  max-width: 970px;
  max-height: 547px;

  ${media.notDesktop`
    max-width: 647px;
    max-height: 365px;
  `};

  ${media.mobile`
    width: 100vw;
    margin: 0 auto;
  `};
`;

const CloseButton = styled.button`
  background: transparent;
  border: none;
  padding: 0;
  width: 16px;
  height: 16px;
  position: absolute;
  right: 0;
  top: -50px;
  display: flex;
  justify-content: flex-end;

  &:focus {
    outline: none;
  }

  ${media.mobile`
    right: 20px;
  `};
`;

export default class VideoOverlay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      videoOpen: false,
    };

    this.openVideo = this.openVideo.bind(this);
    this.closeVideo = this.closeVideo.bind(this);
    this.handleEscDown = this.handleEscDown.bind(this);

    this.videoRef = React.createRef();
    this.timeout = null;
  }

  componentDidMount() {
    window.addEventListener("keydown", this.handleEscDown);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleEscDown);

    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  handleEscDown(e) {
    if (e.keyCode === 27) {
      this.closeVideo();
    }
  }

  openVideo(e) {
    this.setState({
      videoOpen: true,
    });

    lockScroll(true, this.videoOverlayRef);

    if (this.videoRef) {
      this.timeout = setTimeout(() => {
        this.videoRef.current.play();
      }, 200);
    }
  }

  closeVideo(e) {
    if (this.videoRef) {
      this.videoRef.current.pause();
    }

    lockScroll(false, this.videoOverlayRef);

    this.setState({
      videoOpen: false,
    });
  }

  render() {
    const { contentfulVideoContent } = this.props;

    return (
      <VideoOverlayWrapper
        className={this.state.videoOpen ? "" : "d-none"}
        ref={r => {
          this.videoOverlayRef = r;
        }}
        onClick={this.closeVideo}
      >
        <VideoContainer onClick={e => e.stopPropagation()}>
          <CloseButton onClick={this.closeVideo}>
            <Icons.Close />
          </CloseButton>
          <MagicVideo
            ref={this.videoRef}
            crossorigin="Anonymous"
            {...contentfulVideoContent}
          />
        </VideoContainer>
      </VideoOverlayWrapper>
    );
  }
}
