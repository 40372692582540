import React from "react";
import styled, { keyframes } from "styled-components";

// Utils
import { Font, Color, rem, responsive } from "../../utils/style";
import { Icons } from "../../utils/svg";

// Animations
const slideIn = keyframes`
  0% {
    transform: translateY(80px);
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const slideInMobile = keyframes`
  0% {
    transform: translateY(40px);
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

// Styled Elements
const HeroContent = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  ${responsive.sm`
    align-items: center;
    margin-top: -40px;
  `};
`;

const HeroTitle = styled.h1`
  font-size: ${rem(40)};
  letter-spacing: -0.59px;
  line-height: 46px;
  margin-bottom: 16px;

  animation: 1s ease-in 0s 1 ${slideInMobile};

  em {
    ${Font.dutch};
  }

  ${responsive.sm`
    text-align: center !important;
    margin-bottom: 32px;
    width: 460px;
    font-size: 40px;
    letter-spacing: -0.59px;
    line-height: 46px;
    animation: 1s ease-in 0s 1 ${slideIn};
  `}

  ${responsive.md`
    width: 615px;
    font-size: 66px;
    letter-spacing: -2px;
    line-height: 80px;
  `}
`;

const HeroCta = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  user-select: none;

  animation: 1s ease-in 0s 1 ${slideInMobile};

  ${responsive.sm`
    animation: 1s ease-in 0s 1 ${slideIn};
  `}

  &:hover {
    color: rgba(20, 43, 111, 0.56);
    .play-button-container {
      background-color: rgba(20, 43, 111, 0.56);
    }
  }

  p {
    font-size: ${rem(20)};
    letter-spacing: 0px;
    line-height: 30px;
    margin-bottom: 0;

    ${responsive.md`
      font-size: 26px;
      letter-spacing: -0.3px;
      line-height: 36px;
    `}
  }

  .play-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background-color: ${Color.ritualBlue};
    margin-left: 8px;

    ${responsive.md`
      height: 20px;
      width: 20px;
    `}

    svg {
      margin-left: 2px;
      height: 6px;
      width: 5px;

      ${responsive.md`
        height: 7px;
        width: 6px;
      `}
    }
  }
`;

export default class Hero extends React.Component {
  render() {
    const {
      headline: {
        childMarkdownRemark: { rawMarkdownBody: headlineRawMarkdown },
      },
      cta: {
        childMarkdownRemark: { rawMarkdownBody: ctaRawMarkdown },
      },
      openVideo,
    } = this.props;

    return (
      <>
        <HeroContent>
          <HeroTitle
            dangerouslySetInnerHTML={{
              __html: headlineRawMarkdown,
            }}
          />
          <HeroCta onClick={openVideo}>
            <p
              dangerouslySetInnerHTML={{
                __html: ctaRawMarkdown,
              }}
            />
            <div className="play-button-container">
              <Icons.Play />
            </div>
          </HeroCta>
        </HeroContent>
      </>
    );
  }
}
