import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";

// Utils
import { Color, Font, rem, responsive } from "../../utils/style";
import { BenefitsIcons } from "../../utils/svg";

// Components
import Container from "../Container";
import Row from "../Row";

// Elements
const BenefitsContainer = styled(Container)`
  margin-top: 56px;

  ${responsive.sm`
    margin-top: 80px;
  `};
`;

const BenefitsColumn = styled.div.attrs({
  className: "col-12 col-sm-10 offset-sm-1",
})`
  padding-left: 20px;
  padding-right: 20px;

  ${responsive.sm`
    padding-left: 0;
    padding-right: 0;
  `};
`;

const BenefitsContent = styled.div`
  position: relative;
  padding: 40px 24px 158px;
  overflow: hidden;

  ${responsive.sm`
    padding: 0;
  `};
`;

const BenefitsTitle = styled.h3`
  ${Font.circular};
  font-size: ${rem(20)};
  line-height: ${rem(30)};
  font-weight: 500;
  margin: 0 0 32px;
  position: relative;
  z-index: 10;

  em {
    ${Font.dutch};
  }

  ${responsive.sm`
    font-size: ${rem(20)};
    line-height: ${rem(32)};
    letter-spacing: -0.18px;
    text-align: center !important;
    margin-bottom: 40px;
    padding-top: 28px;
    position: relative;

    &::before {
      content: "";
      width: 32px;
      height: 4px;
      background-color: ${Color.ritualBlue};
      position: absolute;
      top: 0;
      left: calc(50% - 16px);
    }
  `};

  ${responsive.md`
    font-size: ${rem(30)};
    line-height: ${rem(36)};
    letter-spacing: -0.4px;
    margin-bottom: 48px;
  `};
`;

const BenefitsList = styled.ol`
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 10;

  ${responsive.sm`
    flex-direction: row;
    justify-content: space-between;
  `}
`;

const BenefitsListItem = styled.li`
  margin-bottom: 16px;

  display: flex;
  align-items: center;

  &:last-child {
    margin-bottom: 0;
  }

  ${responsive.sm`
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 0 16px 0 0;
    flex: 0 0 calc(33.333% - ((16px * 2) / 3));

    &:last-child {
      margin-right: 0;
    }
  `};

  ${responsive.md`
    margin: 0 20px 0 0;
    flex: 0 0 calc(33.333% - ((20px * 2) / 3));

    &:last-child {
      margin-right: 0;
    }
  `};
`;

const BenefitsListItemCopy = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${Font.circular};
  font-size: ${rem(18)};
  line-height: ${rem(28)};
  font-weight: 500;

  svg {
    height: 20px;
    width: auto;
    margin-right: 16px;
  }

  ${responsive.sm`
    flex-direction: column;
    font-size: ${rem(18)};
    line-height: ${rem(28)};
    font-weight: 500;
    margin-bottom: 16px;

    svg {
      height: 25px;
      width: auto;
      margin: 0 0 8px;
    }
  `};

  ${responsive.md`
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: ${rem(22)};
    line-height: ${rem(32)};
    letter-spacing: -0.2px;
    margin-bottom: 24px;

    svg {
      margin: 0 16px 0 0;
    }
  `};
`;

const DesktopImage = styled(Img)`
  width: 100%;
  height: auto;
  display: none;

  ${responsive.sm`
    display: block;
  `};
`;

const MobileImage = styled(Img)`
  position: absolute !important;
  bottom: 0;
  left: calc(50% - (470px / 2));
  height: 169.33px;
  width: 524.33px;

  ${responsive.sm`
    display: none;
  `};
`;

const MobileBackgroundImageWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 40px;
  left: 0;
  border-radius: 8px;
  overflow: hidden;

  ${responsive.sm`
    display: none;
  `};
`;

const MobileBackgroundImage = styled(Img)`
  position: absolute !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;

  ${responsive.sm`
    display: none;
  `};
`;

export default class IngredientBenefits extends React.Component {
  render() {
    const {
      backgroundImage,
      benefits,
      benefitsMobileImage,
      desktopImages,
      mobileImage,
      title,
    } = this.props;

    const titleCopy = title.replace("<p>", "").replace("</p>", "");

    return (
      <BenefitsContainer>
        <Row>
          <BenefitsColumn>
            <BenefitsContent>
              <BenefitsTitle
                dangerouslySetInnerHTML={{
                  __html: titleCopy,
                }}
              />
              <BenefitsList>
                {benefits.map((benefit, i) => {
                  const Icon = BenefitsIcons[benefit.icon];
                  return (
                    <BenefitsListItem key={i}>
                      <BenefitsListItemCopy>
                        <Icon />
                        {benefit.copy}
                      </BenefitsListItemCopy>
                      <DesktopImage
                        fluid={desktopImages[i].fluid}
                        alt={desktopImages[i].description}
                        loading="eager"
                        style={{
                          userSelect: "none",
                          userDrag: "none",
                          pointerEvents: "none",
                          touchCallout: "none",
                        }}
                      />
                    </BenefitsListItem>
                  );
                })}
              </BenefitsList>
              <MobileBackgroundImageWrapper>
                <MobileBackgroundImage
                  fluid={backgroundImage.fluid}
                  alt={backgroundImage.description}
                  loading="eager"
                  style={{
                    userSelect: "none",
                    userDrag: "none",
                    pointerEvents: "none",
                    touchCallout: "none",
                  }}
                />
              </MobileBackgroundImageWrapper>
              <MobileImage
                fluid={benefitsMobileImage.fluid}
                alt={benefitsMobileImage.description}
                loading="eager"
                style={{
                  userSelect: "none",
                  userDrag: "none",
                  pointerEvents: "none",
                  touchCallout: "none",
                }}
              />
            </BenefitsContent>
          </BenefitsColumn>
        </Row>
      </BenefitsContainer>
    );
  }
}
