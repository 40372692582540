import React from "react";
import styled, { keyframes } from "styled-components";
import Img from "gatsby-image";

// Services
import intl from "../../services/intl";

// Components
import Container from "../../components/Container";
import Row from "../../components/Row";
import RitualButton from "../../components/global/RitualButton";
import MagicLink from "../../components/MagicLink";
import StudyDesignModal from "./StudyDesignModal";
import Text from "../Text";

import { Font, Color, rem, responsive, rgba } from "../../utils/style";
import { Icons, ClinicalIcons } from "../../utils/svg";
import metrics from "../../utils/metrics";

const slideInHeading = keyframes`
  0% {
    transform: translateY(30px);
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const slideInIcons = keyframes`
  0% {
    transform: translateY(200px);
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const IntroContainer = styled(Container).attrs({
  role: "section",
})``;

const StudyDesignSection = styled.div.attrs({
  className: "col-12 col-md-10 offset-md-1",
})`
  padding: 0 20px;

  ${responsive.sm`
    padding: 0px 0px;
  `}
`;

const IntroHeader = styled.div`
  h2 {
    ${Font.circular};
    font-size: ${rem(30)};
    font-weight: 500;
    letter-spacing: -0.4px;
    line-height: 36px;
    margin-bottom: 40px;

    em {
      ${Font.dutch}
    }

    animation: 1.3s ease-in-out 0s 1 ${slideInHeading};

    ${responsive.sm`
      text-align: center;
    `}

    ${responsive.md`
      font-size: ${rem(48)};
      letter-spacing: -1.4px;
      line-height: 54px;
      margin-bottom: 56px;
    `}
  }
`;

const StudyDesign = styled.div`
  color: ${rgba(Color.ritualBlue, 0.56)};
  font-size: 14px;
  letter-spacing: 1.2px;
  line-height: 20px;
  text-transform: uppercase;
  margin-bottom: 24px;

  animation: 1.3s ease-in-out 0s 1 ${slideInIcons};

  ${responsive.sm`
    text-align: center;
    margin-bottom: 28px;
  `}

  ${responsive.md`
    font-size: ${rem(18)};
    line-height: 28px;
    margin-bottom: 40px;
  `}

  button {
    appearance: none;
    border: none;
    background: none;

    font: inherit;
    color: inherit;
    border-bottom: 2px solid ${rgba(Color.ritualBlue, 0.56)};

    padding: 0;
    margin: 0;
    cursor: pointer;

    [data-whatintent="mouse"] &:focus,
    [data-whatintent="touch"] &:focus {
      outline: none;
    }
  }
`;

const IconSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 80px;

  animation: 1.3s ease-in-out 0s 1 ${slideInIcons};

  ${responsive.sm`
    flex-direction: row;
    justify-content: center;
  `}

  ${responsive.md`
    padding-bottom: 120px;
  `}
`;

const DesignElement = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  svg {
    min-width: 32px;
    min-height: 32px;
    max-width: 40px;
    max-height: 40px;
    margin-right: 16px;

    ${responsive.sm`
      margin-right: 0px;
    `}
  }

  p {
    margin: 0;
    text-align: left;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0px;
    line-height: 22px;

    br {
      display: none;
    }
  }

  ${responsive.sm`
    width: 134px;
    flex-direction: column;
    margin-right: 8px;
    margin-bottom: 0;

    &:last-of-type {
      margin-right: 0px;
    }

    p {
      text-align: center;

      br {
        display: block;
      }
    }
  `}

  ${responsive.md`
    margin-right: 40px;
  `}
`;

const ResultsSection = styled.div`
  padding: 0 20px;

  ${responsive.sm`
    padding: 0 0;
  `}

  display: flex;
  flex-direction: column;
  margin-bottom: 80px;

  ${responsive.sm`
    flex-direction: row;
    background-color: ${rgba(Color.ritualGrey, 0.56)};
    border-radius: 8px;
    padding: 40px 60px;
    justify-content: space-between;
    align-items: center;
  `};

  ${responsive.md`
    margin-bottom: 120px;
  `};

  ${responsive.lg`
    padding-left: 80px;
    padding-right: 80px;
  `};
`;

const ResultsDescription = styled.div`
  padding-bottom: 40px;

  ${responsive.sm`
    width: 50%;
  `}

  .header {
    ${Font.circular}
    color: #7B88AE;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0px;
    line-height: 20px;
    margin-bottom: 0;
    padding-bottom: 24px;

    ${responsive.md`
      font-size: 18px;
      letter-spacing: 1.2px;
      line-height: 28px;
      padding-bottom: 40px;
    `}
  }
`;

const ResultsCtaButton = styled(RitualButton)`
  width: 100%;
  max-width: 281px;
`;

const Statement = styled.p`
  font-size: 22px;
  letter-spacing: -0.2px;
  line-height: 32px;
  margin-bottom: 0;
  padding-bottom: 40px;

  ${responsive.md`
    font-size: 30px;
    letter-spacing: -0.4px;
    line-height: 36px;
    padding-right: 120px;
  `};

  ${responsive.lg`
    padding-right: 230px;
  `};

  .results-statement-product-link {
    border-bottom: 2px solid ${Color.ritualBlue};

    &:hover {
      color: ${rgba(Color.ritualBlue, 0.56)};
      border-bottom: 2px solid ${rgba(Color.ritualBlue, 0.56)};
    }

    em {
      ${Font.dutch}
    }
  }
`;

const ResultsData = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${rgba(Color.ritualGrey, 0.56)};
  border-radius: 8px;
  padding: 32px;

  ${responsive.sm`
    padding: 0px;
    width: 50%;
    padding-left: 40px;
    background-color: transparent;
    border-radius: 0px;
  `}
`;

const ResultsDataComponent = styled.div`
  &:first-of-type {
    border-bottom: 1px solid ${rgba(Color.ritualBlue, 0.24)};
  }

  padding: 24px 0px;

  .valueContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    svg {
      height: 25px;
      width: 17px;
      margin-right: 13px;

      ${responsive.md`
        height: 45px;
        width: 32px;
        margin-top: 7px;
      `}
    }

    p.value {
      font-size: 40px;
      letter-spacing: -0.59px;
      line-height: 46px;
      margin-bottom: 0px;

      ${responsive.md`
        font-size: 66px;
        letter-spacing: -2px;
        line-height: 72px;
      `}
    }
  }

  .nutrientContainer {
    padding-left: 31px;

    ${responsive.md`
      padding-left: 32px;
    `};

    ${responsive.md`
      padding-left: 45px;
    `};
  }

  p.nutrient {
    ${Font.dutch}
    font-style: italic;
    font-size: 30px;
    font-style: lic;
    letter-spacing: -0.4px;
    line-height: 36px;

    ${responsive.md`
      font-size: 48px;
      letter-spacing: -1.4px;
      line-height: 54px;
    `}
  }

  p.qualifier {
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0px;
    line-height: 18px;

    ${responsive.md`
      font-size: 18px;
      line-height: 28px;
    `}
  }
`;

const QuoteSection = styled.div`
  display: flex;
  flex-direction: column;

  padding: 0px 20px 80px;
  border-radius: 3px;

  ${responsive.sm`
    flex-direction: row;
    align-items: center;
    padding: 0px 0px 80px
  `}

  ${responsive.md`
    padding-bottom: 120px;
  `}
`;

const PhotoContainer = styled.div`
  position: relative;
  max-height: 200px;

  ${responsive.md`
    max-height: 214px;
  `}

  ${responsive.lg`
    max-height: 270px;
  `}
`;

const PhotoMask = styled.div`
  position: relative;
  margin: auto;
  height: 182px;
  width: 182px;
  margin-bottom: 8px;

  ${responsive.sm`
    height: 160px;
    width: 160px;
  `}

  ${responsive.md`
    height: 214px;
    width: 214px;
  `}

  ${responsive.lg`
    height: 270px;
    width: 270px;
  `}
`;

const QuotationMark = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  height: 48px;
  width: 48px;
  background: ${Color.ritualYellow};
  border-radius: 50%;
  z-index: 2;

  ${responsive.sm`
    height: 42px;
    width: 42px;
  `}

  ${responsive.md`
    top: 0;
    bottom: 0;
    height: 63px;
    width: 63px;
  `}

  ${responsive.lg`
    height: 80px;
    width: 80px;
  `}

  p {
    color: ${Color.white};
    font-size: 60px;
    ${Font.circular};
    font-weight: 500;
    letter-spacing: -1.82px;
    line-height: 60px;
    padding-top: 8px;
    padding-left: 13px;

    ${responsive.sm`
      font-size: 53px;
      letter-spacing: -1.6px;
      line-height: 52.75px;
      padding-top: 7px;
      padding-left: 11px;
    `}

    ${responsive.md`
      font-size: 78px;
      letter-spacing: -2.37px;
      line-height: 78.18px;
      padding-top: 12px;
      padding-left: 16px;
    `}

    ${responsive.lg`
      font-size: 100px;
      letter-spacing: -3.03px;
      line-height: 100px;
      padding-top: 13px;
      padding-left: 21px;
    `}
  }
`;

const QuoteContainer = styled.div`
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 26px;
  text-align: center;

  .text {
    ${Font.circular};
    font-size: ${rem(16)};
    line-height: ${rem(26)};
    font-weight: 500;
    letter-spacing: 0;
    margin: 0 0 24px;
    padding: 0;

    ${responsive.sm`
      text-align: left;
    `};

    ${responsive.md`
      font-size: ${rem(22)};
      line-height: ${rem(32)};
      letter-spacing: -0.2px;
      margin-bottom: 40px;
    `};
  }

  .name {
    ${Font.circular};
    font-size: ${rem(16)};
    line-height: ${rem(26)};
    font-weight: 500;
    letter-spacing: 0;
    margin: 0 0 8px;
    padding: 0;

    ${responsive.sm`
      text-align: left;
    `};

    ${responsive.md`
      font-size: ${rem(18)};
      line-height: ${rem(28)};
      letter-spacing: 0;
    `};
  }

  .title {
    ${Font.circular};
    font-size: ${rem(16)};
    line-height: ${rem(26)};
    font-weight: 300;
    letter-spacing: 0;
    color: ${rgba(Color.ritualBlue, 0.56)};
    margin: 0;
    padding: 0;

    ${responsive.sm`
      text-align: left;
    `};
  }
`;

export default class Introduction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      studyDesignModalOpen: false,
    };
    this.openStudyDesignModal = this.openStudyDesignModal.bind(this);
    this.closeStudyDesignModal = this.closeStudyDesignModal.bind(this);
  }

  openStudyDesignModal(e) {
    e.preventDefault();
    if (!this.state.studyDesignModalOpen) {
      this.setState({
        studyDesignModalOpen: true,
      });
    }
  }

  closeStudyDesignModal() {
    this.setState({
      studyDesignModalOpen: false,
    });
  }

  trackCTAClick() {
    let event = {
      title: "Shop Essential for Women 18+",
      location: "Clinical Study Page",
      nonInteraction: false,
    };
    metrics.track("CTA Clicked", event);
  }

  renderDesignElement(element, i) {
    let IconElement = ClinicalIcons[element.icon];
    return (
      <DesignElement key={i}>
        <IconElement />
        <p dangerouslySetInnerHTML={{ __html: element.label }} />
      </DesignElement>
    );
  }

  renderResultsData(element, i) {
    return (
      <ResultsDataComponent key={i}>
        <div className="valueContainer">
          <Icons.ArrowRoundedUp />
          <p className="value">{element.value}%</p>
        </div>
        <div className="nutrientContainer">
          <p className="nutrient">{element.nutrient}</p>
          <p className="qualifier">{element.qualifier}</p>
        </div>
      </ResultsDataComponent>
    );
  }

  render() {
    let {
      introHeader,
      introStudyDesignContent,
      introResultsCTA,
      introResultsProductLink,
      introResultsHeadline,
      introResultsData,
      scientists,
    } = this.props.data.allContentfulClinicalPage.edges[0].node;

    let { studyDesignModalOpen } = this.state;

    const statement = JSON.parse(
      introResultsHeadline.childMarkdownRemark.rawMarkdownBody,
    );
    const statement1st = statement.first;
    const statement2nd = statement.second;
    const resultsData = JSON.parse(
      introResultsData.childMarkdownRemark.rawMarkdownBody,
    );
    const studyDesignContent = JSON.parse(
      introStudyDesignContent.childMarkdownRemark.rawMarkdownBody,
    );
    const scientist = scientists.find(scientist =>
      scientist.name.includes("Mastaneh"),
    ); // Mastenah

    return (
      <IntroContainer>
        <Row>
          {/* Study Design */}
          <StudyDesignSection>
            <IntroHeader
              dangerouslySetInnerHTML={{
                __html: `${introHeader.childMarkdownRemark.html}`,
              }}
            />
            <StudyDesign>
              <p>
                <Text
                  id="clinical.intro.gold-standard-title"
                  defaultMessage="GOLD STANDARD"
                />{" "}
                <button onClick={this.openStudyDesignModal}>
                  <Text
                    id="clinical.intro.study-design-title"
                    defaultMessage="STUDY DESIGN"
                  />
                </button>
              </p>
            </StudyDesign>
            <IconSection>
              {studyDesignContent.map((element, i) => {
                return this.renderDesignElement(element, i);
              })}
            </IconSection>
          </StudyDesignSection>
          <StudyDesignModal
            features={studyDesignContent}
            isOpen={studyDesignModalOpen}
            onRequestClose={this.closeStudyDesignModal}
          />

          {/* Results */}
          <ResultsSection>
            <ResultsDescription>
              <p className="header">
                <Text
                  id="clinical.intro.results-p"
                  defaultMessage="WHAT WE FOUND"
                />
              </p>

              <Statement>
                {statement1st}
                <MagicLink
                  to={introResultsProductLink}
                  className="results-statement-product-link"
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: intl.t(
                        "clinical.intro.efw",
                        "Essential <em>for Women</em>",
                      ),
                    }}
                  />
                </MagicLink>
                {statement2nd}
              </Statement>

              <ResultsCtaButton
                onClick={this.trackCTAClick.bind(this)}
                to="/products/essential-for-women-multivitamin"
              >
                {introResultsCTA}
              </ResultsCtaButton>
            </ResultsDescription>

            <ResultsData>
              {resultsData.map((element, i) => {
                return this.renderResultsData(element, i);
              })}
            </ResultsData>
          </ResultsSection>

          {/* Quote */}
          <QuoteSection>
            <PhotoContainer className="col-12 col-sm-3 offset-sm-1">
              <PhotoMask>
                <Img
                  className=""
                  fluid={scientist.image.fluid}
                  loading="eager"
                  backgroundColor={Color.ritualGrey}
                  alt={scientist.image.description}
                  ariaLabel={scientist.image.description}
                  style={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                    width: "100%",
                    height: "100%",
                    zIndex: "1",
                    userSelect: "none",
                    userDrag: "none",
                    pointerEvents: "none",
                    touchCallout: "none",
                    borderRadius: "50%",
                  }}
                />
                <QuotationMark>
                  <p>&#8221;</p>
                </QuotationMark>
              </PhotoMask>
            </PhotoContainer>

            <QuoteContainer className="col-12 col-sm-6 offset-sm-1">
              <p className="text">{scientist.clinicalQuote}</p>
              <p className="name">{scientist.name}</p>
              <p className="title">{scientist.title}</p>
            </QuoteContainer>
          </QuoteSection>
        </Row>
      </IntroContainer>
    );
  }
}
